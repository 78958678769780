<div class="sliders">
    <div class="slider">
        <em class="icon icon-battery"></em>
        <span class="title">{{ 'Energy storage' | translate }}</span>
        <app-slider
            [value]="energyStorageSliderValue"
            [recommended]="'Recommended size of energy storage: <strong>size kWh</strong>' | translate: {size: recommendedEnergyStorageSize | number: '1.1-1'}"
            (updateValue)="updateEnergyStorageSlider($event)"
        ></app-slider>
        <span class="consumption"><span>{{ energyStorageConsumption | number: '1.2-2' }}</span> kWh/{{ 'year' | translate }}</span>
    </div>
    <div class="slider">
        <em class="icon icon-charging-car"></em>
        <span class="title">{{ 'Charging the car' | translate }}</span>
        <app-slider
            [value]="chargingCarSliderValue"
            [recommended]="'Car range on solar energy: <strong>range kw/year</strong>' | translate: {range: solarEnergyCarRange | number: '1.0-0'}"
            (updateValue)="updateChargingCarSlider($event)"
        ></app-slider>
        <span class="consumption"><span>{{ carChargingConsumption | number: '1.2-2' }}</span> kWh/{{ 'year' | translate }}</span>
    </div>
</div>

<hr>

<div class="battery-top-container" [class.open]="open">
    <div class="battery">
        <div class="battery-image">
            <img ngSrc="/assets/battery.webp" width="206" height="280" alt="Battery">
        </div>
        <div class="battery-container">
            <div class="battery-headline">
                <div>
                    <div class="battery-title">{{ 'Add Battery' | translate }}</div>
                    <div class="battery-sub-title">{{ 'Add' | translate }} {{ batteryTypeLabel }}</div>
                </div>
                <div>
                    <app-toggle [active]="open" (toggleEvent)="toggleSection($event)"></app-toggle>
                </div>
            </div>
            <div class="battery-headline">
                <div>
                    <div class="battery-title">{{ 'Number of batteries' | translate }}</div>
                    <div class="battery-sub-title" [innerHTML]="'Recommended size of energy storage: <strong>size kWh</strong>' | translate: {size: recommendedEnergyStorageSize | number: '1.1-1'}"></div>
                </div>
                <div>
                    <app-count-input [value]="batteryCount" (changeCount)="changeBatteryCount($event)"></app-count-input>
                </div>
            </div>
            <div class="battery-headline">
                <div>
                    <div class="battery-title">{{ 'Battery type' | translate }}</div>
                </div>
                <div>
                    <ng-container *ngIf="isReadonly">{{ batteryTypeLabel }}</ng-container>
                    <select2
                        *ngIf="!isReadonly"
                        required
                        [value]="batteryTypeValue"
                        [data]="batteryTypeOptions"
                        (update)="batteryTypeUpdate($event)"
                    ></select2>
                </div>
            </div>
        </div>
    </div>

    <div class="backup-guide">
        <p class="guide-title">{{ 'Why system with batteries?' | translate }}</p>
        <ul class="check-list">
            <li>{{ 'Solar energy at night' | translate }}</li>
            <li>{{ 'More savings' | translate }}</li>
            <li>{{ 'Faster return on investment' | translate }}</li>
        </ul>
    </div>
</div>
