import {
    HttpErrorResponse,
    HttpEvent,
    HttpRequest,
} from '@angular/common/http';
import { Observable, retry, timer } from 'rxjs';

const maxRetries: number = 3;
const delayMs: number = 100;

const delayNotifier = (error: unknown): Observable<number> => {
    if (
        error instanceof HttpErrorResponse &&
        (error.status < 100 || error.status >= 500)
    ) {
        return timer(delayMs);
    }

    throw error;
};

export const retryInterceptorFn = (
    req: HttpRequest<unknown>,
    handle: (req: HttpRequest<unknown>) => Observable<HttpEvent<unknown>>,
): Observable<HttpEvent<unknown>> => {
    return handle(req).pipe(retry({ count: maxRetries, delay: delayNotifier }));
};
