import {
    CurrencyPipe,
    DecimalPipe,
    NgIf,
    NgOptimizedImage,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CountInputComponent } from '../../../../components/results/count-input/count-input.component';
import { ImagePanelsComponent } from '../../../../components/results/image-panels/image-panels.component';
import { ProgressBarComponent } from '../../../../components/results/progress-bar/progress-bar.component';
import { LoadingComponent } from '../../../../components/shared/loading/loading.component';
import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
} from '../../../../domain/api-result';

@Component({
    selector: 'app-concept',
    standalone: true,
    imports: [
        CountInputComponent,
        NgIf,
        NgOptimizedImage,
        ProgressBarComponent,
        TranslateModule,
        ImagePanelsComponent,
        CurrencyPipe,
        DecimalPipe,
        LoadingComponent,
    ],
    templateUrl: './concept.component.html',
    styleUrl: './concept.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptComponent {
    @Input() isLoading: boolean = false;
    @Input() image!: string | undefined;
    @Input() apiResultSolarPanelConfiguration:
        | ApiResultSolarPanelConfiguration
        | undefined;
    @Input() companyResponse: CompanyResponse | undefined;
    @Input() allowIncrement: boolean = true;
    @Input() allowDecrement: boolean = false;

    changePanelsCountEvent: OutputEmitterRef<number> = output<number>();

    get panelsCount(): number {
        return this.apiResultSolarPanelConfiguration?.totalPanelCount ?? 0;
    }

    get energyOffsetPercent(): number {
        return this.apiResultSolarPanelConfiguration?.energyOffsetPercent ?? 0;
    }

    get yearlyEnergyDcKwh(): number {
        return this.apiResultSolarPanelConfiguration?.yearlyEnergyDcKwh ?? 0;
    }

    get systemPower(): number {
        return this.apiResultSolarPanelConfiguration?.systemPowerDcKwh ?? 0;
    }

    get consumption(): string {
        const energyPrice: number =
            this.companyResponse?.energy_price_for_kwh ?? 0.693;

        return ((this.oldBillMonthly / energyPrice) * 12).toFixed(2);
    }

    get oldBillMonthly(): number {
        return this.apiResultSolarPanelConfiguration?.oldBillMonthly ?? 0;
    }

    get newBillMonthly(): number {
        const newBill: number | undefined =
            this.apiResultSolarPanelConfiguration?.newBillMonthly;

        if (undefined === newBill || 0 > newBill) {
            return 0;
        }

        return newBill;
    }

    get currency(): string {
        return this.companyResponse?.currency_code ?? 'USD';
    }

    changePanelsCount(count: number): void {
        this.changePanelsCountEvent.emit(count);
    }
}
