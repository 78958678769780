import { NgIf, NgOptimizedImage } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CompanyResponse } from '../../../domain/api-result';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-aside-company-data',
    standalone: true,
    imports: [NgOptimizedImage, NgIf, LoadingComponent],
    templateUrl: './aside-company-data.component.html',
    styleUrl: './aside-company-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideCompanyDataComponent implements OnInit {
    @Input() isLoading: boolean = false;

    companyData: CompanyResponse | undefined;

    constructor(private readonly activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.companyData = this.activatedRoute.snapshot.data['company'];
    }

    get companyLogo(): string | null {
        return this.companyData?.logo ?? null;
    }

    get companyName(): string {
        return this.companyData?.name ?? '';
    }
}
