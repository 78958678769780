import { Component, Input } from '@angular/core';

/**
 * Renders loading indicator (facebook style) over a given component
 * Control displaying indicator via [isLoading]
 * And use [showContent] to decide if content should be displayed while [isLoading] is set to "true"
 *
 * Example usage:
 * @example
 * <app-loading [isLoading]="false" [showContent]="true">
 *     <div>something</div>
 * </app-loading>
 */
@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [],
    template:
        '<div [class.is-loading]="isLoading" [class.inline]="inline" [class.show-content]="showContent"><ng-content></ng-content></div>',
    styleUrl: './loading.component.scss',
})
export class LoadingComponent {
    @Input() inline: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() showContent: boolean = false;
}
