import { NgIf, NgOptimizedImage } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2Data,
    Select2Group,
    Select2Module,
    Select2Option,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';

import { CountInputComponent } from '../../../../components/results/count-input/count-input.component';
import { ToggleComponent } from '../../../../components/results/toggle/toggle.component';
import { CompanyResponse, Module } from '../../../../domain/api-result';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-components-select',
    standalone: true,
    imports: [
        NgOptimizedImage,
        CountInputComponent,
        Select2Module,
        ToggleComponent,
        TranslateModule,
        NgIf,
    ],
    templateUrl: './components-select.component.html',
    styleUrl: './components-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentsSelectComponent implements OnInit {
    @Input() sunnaSlug: string = '';
    @Input() companyResponse: CompanyResponse | undefined;

    @Input() set solarPanel(value: Select2Option | undefined) {
        this.setSelectedSolarPanel(value);
    }
    @Input() set inverter(value: Select2Option | undefined) {
        this.setSelectedInverter(value);
    }

    solarPanelValue: Select2UpdateValue = false;
    inverterValue: Select2UpdateValue = false;

    solarPanelLabel: string | undefined;
    inverterLabel: string | undefined;

    solarPanelOptions: Select2Data = [];
    inverterOptions: Select2Data = [];

    solarPanelUpdateEvent: OutputEmitterRef<string> = output<string>();
    inverterUpdateEvent: OutputEmitterRef<string> = output<string>();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly modalService: ModalService,
    ) {}

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    async ngOnInit(): Promise<void> {
        const modules: Module[] | undefined =
            this.activatedRoute.snapshot.data['modules'];

        if (undefined === modules) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        modules.forEach((module: Module): void => {
            if ('panel' === module.category) {
                this.solarPanelOptions.push({
                    label: this.getModuleLabel(module),
                    value: module.id,
                });
            } else if ('inverter' === module.category) {
                this.inverterOptions.push({
                    label: this.getModuleLabel(module),
                    value: module.id,
                });
            }
        });

        if (0 < this.solarPanelOptions.length) {
            this.solarPanelUpdate({
                value: (this.solarPanelOptions[0] as Select2Option).value,
            } as Select2UpdateEvent);
        }

        if (0 < this.inverterOptions.length) {
            this.inverterUpdate({
                value: (this.inverterOptions[0] as Select2Option).value,
            } as Select2UpdateEvent);
        }
    }

    private getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.companyResponse?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    getOption(
        options: Select2Data,
        value: Select2Value,
    ): Select2Option | undefined {
        return options.find((option: Select2Option | Select2Group): boolean => {
            return 'value' in option && option.value === value;
        }) as Select2Option | undefined;
    }

    setSelectedSolarPanel(option: Select2Option | undefined): void {
        this.solarPanelValue = option?.value ?? '';
        this.solarPanelLabel = option?.label ?? '';
    }

    setSelectedInverter(option: Select2Option | undefined): void {
        this.inverterValue = option?.value ?? '';
        this.inverterLabel = option?.label ?? '';
    }

    solarPanelUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.setSelectedSolarPanel(
            this.getOption(this.solarPanelOptions, event.value),
        );

        this.solarPanelUpdateEvent.emit(event.value);
    }

    inverterUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.setSelectedInverter(
            this.getOption(this.inverterOptions, event.value),
        );

        this.inverterUpdateEvent.emit(event.value);
    }
}
