import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RedirectCommand,
    ResolveFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../environments/environment';
import { CompanyResponse } from '../domain/api-result';
import { SunnaApiService } from '../services/sunna-api.service';

export const companyResolver: ResolveFn<CompanyResponse | undefined> = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Promise<CompanyResponse | RedirectCommand | undefined> => {
    const router: Router = inject(Router);
    const sunnaApiService: SunnaApiService = inject(SunnaApiService);

    try {
        return await firstValueFrom(
            sunnaApiService.company(route.paramMap.get('companySlug')!),
        );
    } catch (error) {
        Sentry.captureException(error);

        if (state.url === `/${environment.DEFAULT_COMPANY_SLUG}`) {
            return undefined;
        }

        return new RedirectCommand(router.parseUrl(''));
    }
};
