<form [formGroup]="phoneForm" (ngSubmit)="formSubmit()">
    <div class="form-group" [class.have-errors]="phone?.invalid && phone?.touched">
        <label for="email">{{ 'Phone' | translate }}</label>
        <input type="tel" formControlName="phone" id="email">
        <div class="form-errors" *ngIf="phone?.invalid && phone?.touched">
            <div *ngIf="phone?.errors?.['required']">{{ 'Phone is required.' | translate }}</div>
        </div>
    </div>
    <div class="form-group checkbox-form-group" [class.have-errors]="agreement?.invalid && agreement?.touched">
        <label>
            <input type="checkbox" formControlName="agreement">
            <span>{{ 'I consent to the processing of personal data and sending the offer.' | translate }}</span>
        </label>
        <div class="form-errors" *ngIf="agreement?.invalid && agreement?.touched">
            <div *ngIf="agreement?.errors?.['required']">{{ 'Agreement is required.' | translate }}</div>
        </div>
    </div>
    <div class="buttons">
        <button class="btn btn-primary btn-with-icon" type="submit">{{ submitText }} <em class="icon icon-chevron-right-full"></em></button>
    </div>
</form>
