import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-aside-footer',
    standalone: true,
    imports: [NgOptimizedImage, TranslateModule],
    templateUrl: './aside-footer.component.html',
    styleUrl: './aside-footer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideFooterComponent {}
