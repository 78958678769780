import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-count-input',
    standalone: true,
    imports: [],
    templateUrl: './count-input.component.html',
    styleUrl: './count-input.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountInputComponent {
    @Input() value: number = 1;
    @Input() stopChange: boolean = false;
    @Input() allowIncrement: boolean = true;
    @Input() allowDecrement: boolean = true;

    changeCount: OutputEmitterRef<number> = output<number>();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    increment(): void {
        if (this.isReadonly || !this.allowIncrement) {
            return;
        }

        const value: number = this.value + 1;

        if (!this.stopChange) {
            this.value = value;
        }

        this.changeCount.emit(value);
        this.changeDetectorRef.detectChanges();
    }

    decrement(): void {
        if (this.isReadonly || !this.allowDecrement) {
            return;
        }

        if (1 === this.value) {
            return;
        }

        const value: number = this.value - 1;

        if (!this.stopChange) {
            this.value = value;
        }

        this.changeCount.emit(value);
        this.changeDetectorRef.detectChanges();
    }
}
