import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import {
    interval,
    map,
    mergeMap,
    Observable,
    Subscriber,
    takeWhile,
} from 'rxjs';

import { CalculationsAsideComponent } from '../../components/calculations/calculations-aside/calculations-aside.component';
import { AddressErrorModalComponent } from '../../components/shared/address-error-modal/address-error-modal.component';
import { fadeInOutAnimation } from '../../domain/animations';
import {
    AnalyzeResponse,
    CompanyResponse,
    JobStatusResponse,
} from '../../domain/api-result';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { FormValues } from '../form/form.component';
import { ModalService } from '../results/services/modal.service';

export interface QueryValues {
    address: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    bill: number | undefined;
    equipment: number | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
}

@Component({
    selector: 'app-calculations',
    standalone: true,
    imports: [CalculationsAsideComponent, AddressErrorModalComponent],
    templateUrl: './calculations.component.html',
    styleUrl: './calculations.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fadeInOutAnimation,
})
export class CalculationsComponent implements OnInit {
    formValues: FormValues = {
        address: '',
        bill: 0,
        email: '',
        equipment: 0,
        firstName: '',
        lastName: '',
        latitude: 0,
        longitude: 0,
    };

    calculationSteps: string[] = [
        'scanning detecting edges',
        'checking sunlight',
        'detecting obstacles',
        'accounting for the distance from the edge',
        'fitting panels',
        'calculation of energy yields',
        'generating visualisation',
    ];
    calculationStep: number = 1;
    intervalTime: number = 1000;

    jobId: string | undefined;
    firstSurpassIndex: number | undefined;
    calculationId: string | undefined;
    sunnaSlug: string | undefined;

    constructor(
        private readonly changeDetector: ChangeDetectorRef,
        private readonly sunnaApiService: SunnaApiService,
        private readonly modalService: ModalService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly languageService: LanguageService,
    ) {}

    ngOnInit(): void {
        const company: CompanyResponse | undefined =
            this.activatedRoute.snapshot.data['company'];

        if (undefined === company) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.languageService.setLanguageOptions(company);

        this.sunnaSlug = company.sunna_slug;

        const queryParams: QueryValues = this.activatedRoute.snapshot
            .queryParams as QueryValues;

        if (
            undefined === queryParams.address ||
            undefined === queryParams.bill ||
            undefined === queryParams.email ||
            undefined === queryParams.equipment ||
            undefined === queryParams.firstName ||
            undefined === queryParams.lastName ||
            undefined === queryParams.latitude ||
            undefined === queryParams.longitude
        ) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.formValues = {
            address: queryParams.address,
            bill: queryParams.bill,
            email: queryParams.email,
            equipment: queryParams.equipment,
            firstName: queryParams.firstName,
            lastName: queryParams.lastName,
            latitude: queryParams.latitude,
            longitude: queryParams.longitude,
        };

        Sentry.metrics.increment('sunna_init', 1, {
            tags: { company: company?.sunna_slug },
        });

        const analyze: AnalyzeResponse | undefined =
            this.activatedRoute.snapshot.data['analyze'];

        if (undefined === analyze) {
            Sentry.metrics.increment('sunna_failed', 1, {
                tags: { company: company?.sunna_slug },
            });

            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.jobId = analyze.jobId;
        this.firstSurpassIndex = analyze.firstSurpassIndex;
        this.calculationId = analyze.calculationId;
        this.enableCheck();
    }

    /**
     * creates interval that every tick requests checkJobStatus
     */
    createInterval(): Observable<string> {
        if (undefined === this.jobId) {
            return new Observable<string>(
                (subscriber: Subscriber<string>): void => {
                    subscriber.error(new Error('Missing jobId'));
                },
            );
        }

        const jobId: string = this.jobId;

        return interval(this.intervalTime).pipe(
            mergeMap(() =>
                this.sunnaApiService
                    .checkJobStatus(jobId)
                    .pipe(map((res: JobStatusResponse): string => res.status)),
            ),
        );
    }

    private goToResults(jobId: string): void {
        this.router
            .navigate([this.sunnaSlug, 'results'], {
                queryParams: {
                    jobId: jobId,
                    firstSurpassIndex: this.firstSurpassIndex ?? 0,
                    calculationId: this.calculationId ?? '',
                    address: this.formValues.address,
                    latitude: this.formValues.latitude,
                    longitude: this.formValues.longitude,
                    bill: this.formValues.bill,
                    equipment: this.formValues.equipment,
                    firstName: this.formValues.firstName,
                    lastName: this.formValues.lastName,
                    email: this.formValues.email,
                },
            })
            .then((): void => {});
    }

    /**
     * subscribes to an interval when jobId is defined
     * then checks status from response
     * if status is different from "done"
     *      checks calculationSteps array for step index and updates animation
     * if status is "done"
     *      emits calculation ended Event and unsubscribes (`takeWhile`)
     */
    enableCheck(): void {
        if (undefined === this.jobId) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        const jobId: string = this.jobId;

        this.createInterval()
            .pipe(
                takeWhile((status: string): boolean => 'done' !== status, true),
            )
            .subscribe({
                next: (status: string): void => {
                    Sentry.metrics.increment('sunna_steps', 1, {
                        tags: { status: status },
                    });

                    if ('done' === status) {
                        this.goToResults(jobId);
                        return;
                    }

                    const index: number = this.calculationSteps.findIndex(
                        (stepStatus: string): boolean => stepStatus === status,
                    );

                    if (-1 !== index) {
                        this.calculationStep = index + 1;
                    } else {
                        this.calculationStep = 1;
                    }

                    this.changeDetector.detectChanges();
                },
                error: (): void => {
                    this.modalService.setAddressErrorModalState(true);
                },
            });
    }

    get displayStep1(): boolean {
        return 1 === this.calculationStep;
    }

    get displayStep2(): boolean {
        return 2 === this.calculationStep;
    }

    get displayStep3(): boolean {
        return (
            3 === this.calculationStep ||
            4 === this.calculationStep ||
            5 === this.calculationStep ||
            6 === this.calculationStep ||
            7 === this.calculationStep
        );
    }

    get displayStep4(): boolean {
        return (
            4 === this.calculationStep ||
            5 === this.calculationStep ||
            6 === this.calculationStep ||
            7 === this.calculationStep
        );
    }

    get displayStep5(): boolean {
        return (
            5 === this.calculationStep ||
            6 === this.calculationStep ||
            7 === this.calculationStep
        );
    }

    get displayStep6(): boolean {
        return 6 === this.calculationStep;
    }

    get displayStep7(): boolean {
        return 7 === this.calculationStep;
    }
}
