import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
    ActiveTabService,
    FinancingType,
} from '../../../services/active-tab.service';

@Component({
    selector: 'app-tab-list',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './tab-list.component.html',
    styleUrl: './tab-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabListComponent implements OnInit {
    protected activeTab: FinancingType = 'cash';

    constructor(
        private readonly activeTabService: ActiveTabService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {
        this.activeTabService.activeTab.subscribe(
            (activeTab: FinancingType): void => {
                this.activeTab = activeTab;
                this.changeDetector.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        this.activeTabService.setActiveTab(this.activeTab);
    }

    changeActiveTab(tab: FinancingType): void {
        this.activeTabService.setActiveTab(tab);
    }
}
