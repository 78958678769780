import { DecimalPipe, NgIf, NgOptimizedImage } from '@angular/common';
import {
    Component,
    Input,
    OnInit,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    Select2Data,
    Select2Group,
    Select2Module,
    Select2Option,
    Select2UpdateEvent,
    Select2UpdateValue,
    Select2Value,
} from 'ng-select2-component';

import { CountInputComponent } from '../../../../components/results/count-input/count-input.component';
import { SliderComponent } from '../../../../components/results/slider/slider.component';
import { ToggleComponent } from '../../../../components/results/toggle/toggle.component';
import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
    Module,
} from '../../../../domain/api-result';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'app-energy-storage',
    standalone: true,
    imports: [
        CountInputComponent,
        ToggleComponent,
        Select2Module,
        NgIf,
        NgOptimizedImage,
        TranslateModule,
        SliderComponent,
        DecimalPipe,
    ],
    templateUrl: './energy-storage.component.html',
    styleUrl: './energy-storage.component.scss',
})
export class EnergyStorageComponent implements OnInit {
    @Input() batteryCount!: number;
    @Input() open: boolean = false;
    @Input() companyResponse: CompanyResponse | undefined;
    @Input() apiResultSolarPanelConfiguration:
        | ApiResultSolarPanelConfiguration
        | undefined;
    @Input() set batteryType(value: Select2Option | undefined) {
        this.setSelectedBatteryType(value);
    }

    energyStorageSliderValue: number = 0;
    chargingCarSliderValue: number = 0;

    batteryTypeValue: Select2UpdateValue = false;
    batteryTypeLabel: string | undefined;
    batteryTypeOptions: Select2Data = [];

    sliderValuesUpdateEvent: OutputEmitterRef<{
        energyStorage: number;
        chargingCar: number;
    }> = output<{ energyStorage: number; chargingCar: number }>();
    batteryTypeUpdateEvent: OutputEmitterRef<string> = output<string>();
    changeBatteryCountEvent: OutputEmitterRef<number> = output<number>();
    toggleBatteryEvent: OutputEmitterRef<boolean> = output<boolean>();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly modalService: ModalService,
    ) {}

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    get yearlyEnergyDcKwh(): number {
        return this.apiResultSolarPanelConfiguration?.yearlyEnergyDcKwh ?? 0;
    }

    get energyStorageConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.energyStorageSliderValue / 100);
    }

    get recommendedEnergyStorageSize(): number {
        return this.energyStorageConsumption / 360;
    }

    get carChargingConsumption(): number {
        return this.yearlyEnergyDcKwh * (this.chargingCarSliderValue / 100);
    }

    get solarEnergyCarRange(): number {
        return this.carChargingConsumption / 0.18;
    }

    getModuleLabel(module: Module): string {
        return `${module.name} <span class="badge badge-success">+${module.price} ${this.companyResponse?.currency_code ?? 'USD'} / 1Wp</span>`;
    }

    async ngOnInit(): Promise<void> {
        const modules: Module[] | undefined =
            this.activatedRoute.snapshot.data['modules'];

        if (undefined === modules) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        modules.forEach((module: Module): void => {
            if ('battery' === module.category) {
                this.batteryTypeOptions.push({
                    label: this.getModuleLabel(module),
                    data: module.name,
                    value: module.id,
                });
            }
        });

        if (0 < this.batteryTypeOptions.length) {
            this.batteryTypeUpdate({
                value: (this.batteryTypeOptions[0] as Select2Option).value,
            } as Select2UpdateEvent);
        }
    }

    getOption(
        options: Select2Data,
        value: Select2Value,
    ): Select2Option | undefined {
        return options.find((option: Select2Option | Select2Group): boolean => {
            return 'value' in option && option.value === value;
        }) as Select2Option | undefined;
    }

    setSelectedBatteryType(option: Select2Option | undefined): void {
        this.batteryTypeValue = option?.value ?? '';
        if (option?.data) {
            this.batteryTypeLabel = option?.data;
        }
    }

    batteryTypeUpdate(event: Select2UpdateEvent): void {
        if ('string' !== typeof event.value) {
            return;
        }

        this.setSelectedBatteryType(
            this.getOption(this.batteryTypeOptions, event.value),
        );

        this.batteryTypeUpdateEvent.emit(event.value);
    }

    changeBatteryCount(count: number): void {
        this.changeBatteryCountEvent.emit(count);
    }

    toggleSection(active: boolean): void {
        this.open = active;

        this.toggleBatteryEvent.emit(active);
    }

    updateEnergyStorageSlider(value: number): void {
        this.energyStorageSliderValue = value;

        this.chargingCarSliderValue = Math.min(
            this.chargingCarSliderValue,
            100 - value,
        );

        this.sliderValuesUpdateEvent.emit({
            chargingCar: this.chargingCarSliderValue,
            energyStorage: this.energyStorageSliderValue,
        });
    }

    updateChargingCarSlider(value: number): void {
        this.chargingCarSliderValue = value;

        this.energyStorageSliderValue = Math.min(
            this.energyStorageSliderValue,
            100 - value,
        );

        this.sliderValuesUpdateEvent.emit({
            chargingCar: this.chargingCarSliderValue,
            energyStorage: this.energyStorageSliderValue,
        });
    }
}
