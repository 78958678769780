import { NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    output,
    OutputEmitterRef,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Select2Data, Select2Module } from 'ng-select2-component';
import { forkJoin } from 'rxjs';

export interface Step1FormValues {
    address: string;
    latitude: number;
    longitude: number;
    bill: number;
    equipment: number;
}

@Component({
    selector: 'app-step-1',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        GoogleMapsModule,
        Select2Module,
        TranslateModule,
    ],
    templateUrl: './step-1.component.html',
    styleUrl: './step-1.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Step1Component implements OnInit, AfterViewInit {
    submitEvent: OutputEmitterRef<Step1FormValues> = output<Step1FormValues>();

    @ViewChild('autocompleteInput', { static: true })
    autocompleteInput!: ElementRef<HTMLInputElement>;

    step1Form: FormGroup = new FormGroup({
        address: new FormControl(null, Validators.required),
        latitude: new FormControl(null, [
            Validators.required,
            Validators.min(-90),
            Validators.max(90),
        ]),
        longitude: new FormControl(null, [
            Validators.required,
            Validators.min(-180),
            Validators.max(180),
        ]),
        bill: new FormControl(null, [Validators.required, Validators.min(0)]),
        equipment: new FormControl(null, Validators.required),
    });

    equipmentOptions: Select2Data = [
        {
            label: 'Photovoltaic panels',
            value: 1,
        },
        {
            label: 'Photovoltaic panels + battery',
            value: 2,
        },
    ];

    constructor(private readonly translateService: TranslateService) {}

    ngOnInit(): void {
        this.step1Form.get('address')?.valueChanges.subscribe((): void => {
            if (null !== this.step1Form.get('latitude')?.value) {
                this.step1Form.get('latitude')?.setValue(null);
            }
            if (null !== this.step1Form.get('longitude')?.value) {
                this.step1Form.get('longitude')?.setValue(null);
            }
        });

        forkJoin([
            this.translateService.get('Photovoltaic panels'),
            this.translateService.get('Photovoltaic panels + battery'),
        ]).subscribe((value: [string, string]): void => {
            this.equipmentOptions[0].label = value[0];
            this.equipmentOptions[1].label = value[1];
        });
    }

    ngAfterViewInit(): void {
        this.getPlaceAutocomplete().then((): null => null);
    }

    private async getPlaceAutocomplete(): Promise<void> {
        const placesLibrary: google.maps.PlacesLibrary =
            (await google.maps.importLibrary(
                'places',
            )) as google.maps.PlacesLibrary;

        const autocomplete: google.maps.places.Autocomplete =
            new placesLibrary.Autocomplete(
                this.autocompleteInput.nativeElement,
                {
                    types: ['address'],
                    fields: ['geometry', 'formatted_address'],
                },
            );

        /**
         * The listener below is currently untestable for some reason
         * best I can do is triggering this event manually via
         *      google.maps.event.trigger(autocomplete, 'place_changed')
         * but then `autocomplete.getPlace() returns undefined
         * and providing input with the correct address does not change output
         */
        google.maps.event.addListener(autocomplete, 'place_changed', (): void =>
            this.setValueFromAutocomplete(autocomplete),
        );
    }

    setValueFromAutocomplete(
        autocomplete: google.maps.places.Autocomplete,
    ): void {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        this.address?.setValue(place.formatted_address);

        this.latitude?.setValue(place.geometry?.location?.lat());
        this.longitude?.setValue(place.geometry?.location?.lng());
    }

    get address(): AbstractControl<string | undefined> | null {
        return this.step1Form.get('address');
    }

    get latitude(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('latitude');
    }

    get longitude(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('longitude');
    }

    get bill(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('bill');
    }

    get equipment(): AbstractControl<number | undefined> | null {
        return this.step1Form.get('equipment');
    }

    formSubmit(): void {
        this.step1Form.markAsTouched();
        this.address?.markAsTouched();
        this.bill?.markAsTouched();
        this.equipment?.markAsTouched();

        if (this.step1Form.invalid) {
            if (this.latitude?.invalid) {
                this.address?.setErrors(this.latitude.errors);
            }

            if (this.longitude?.invalid) {
                this.address?.setErrors(this.longitude.errors);
            }

            return;
        }

        this.submitEvent.emit(this.step1Form.value);
    }
}
