import { NgIf } from '@angular/common';
import { Component, Input, output, OutputEmitterRef } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-phone-form',
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, TranslateModule],
    templateUrl: './phone-form.component.html',
    styleUrl: './phone-form.component.scss',
})
export class PhoneFormComponent {
    @Input() submitText!: string;

    formSubmitEvent: OutputEmitterRef<string> = output();

    phoneForm: FormGroup = new FormGroup({
        phone: new FormControl(null, [Validators.required]),
        agreement: new FormControl(false, [
            Validators.required,
            Validators.requiredTrue,
        ]),
    });

    get phone(): AbstractControl<string | null> | null {
        return this.phoneForm.get('phone');
    }

    get agreement(): AbstractControl<boolean> | null {
        return this.phoneForm.get('agreement');
    }

    formSubmit(): void {
        this.phoneForm.markAllAsTouched();

        if (this.phoneForm.invalid) {
            return;
        }

        this.formSubmitEvent.emit(this.phoneForm.get('phone')?.value as string);

        this.phoneForm.markAsUntouched();
        this.phone?.setValue(null);
        this.agreement?.setValue(false);
    }
}
