import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CompanyResponse } from '../domain/api-result';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    constructor(private readonly translateService: TranslateService) {}

    setLanguageOptions(company: CompanyResponse | undefined): void {
        let preferredLang: string = '';

        if (undefined !== company) {
            preferredLang = company.language_code;
        }

        const allowedLanguages: string[] = ['en', 'pl', 'pt', 'es'];

        this.translateService.addLangs(allowedLanguages);
        this.translateService.setDefaultLang('en');

        if (allowedLanguages.includes(preferredLang)) {
            this.translateService.use(preferredLang);
            return;
        }

        for (const curLang of navigator.languages) {
            if (allowedLanguages.includes(curLang)) {
                this.translateService.use(curLang);
                return;
            }
        }
    }
}
