<h4>{{ 'The last step before we analyze your roof and prepare a concept.' | translate }}</h4>
<p>{{ 'We will also send a copy of the results to your email address.' | translate }}</p>

<form [formGroup]="step2Form" (ngSubmit)="formSubmit()">
    <div class="form-group" [class.have-errors]="firstName?.invalid && firstName?.touched">
        <label for="first-name">{{ 'First Name' | translate }}</label>
        <input type="text" placeholder="Joe" formControlName="firstName" id="first-name">
        <div class="form-errors" *ngIf="firstName?.invalid && firstName?.touched">
            <div *ngIf="firstName?.errors?.['required']">{{ 'First Name is required.' | translate }}</div>
        </div>
    </div>
    <div class="form-group" [class.have-errors]="lastName?.invalid && lastName?.touched">
        <label for="last-name">{{ 'Last Name' | translate }}</label>
        <input type="text" placeholder="Doe" formControlName="lastName" id="last-name">
        <div class="form-errors" *ngIf="lastName?.invalid && lastName?.touched">
            <div *ngIf="lastName?.errors?.['required']">{{ 'Last Name is required.' | translate }}</div>
        </div>
    </div>
    <div class="form-group" [class.have-errors]="email?.invalid && email?.touched">
        <label for="email">{{ 'Email' | translate }}</label>
        <input type="email" placeholder="joe@doe.com" formControlName="email" id="email">
        <div class="form-errors" *ngIf="email?.invalid && email?.touched">
            <div *ngIf="email?.errors?.['required']">{{ 'Email is required.' | translate }}</div>
            <div *ngIf="email?.errors?.['email']">{{ 'Not a valid email.' | translate }}</div>
        </div>
    </div>
    <div class="form-group checkbox-form-group" [class.have-errors]="agreement?.invalid && agreement?.touched">
        <label>
            <input type="checkbox" formControlName="agreement">
            <span>{{ 'I consent to the processing of personal data and sending the offer.' | translate }}</span>
        </label>
        <div class="form-errors" *ngIf="agreement?.invalid && agreement?.touched">
            <div *ngIf="agreement?.errors?.['required']">{{ 'Agreement is required.' | translate }}</div>
        </div>
    </div>
    <div class="flex">
        <div class="step">{{ 'Step' | translate }} 2/2</div>
        <app-loading [isLoading]="isSubmitted" [showContent]="true">
            <div class="buttons">
                <button class="btn btn-with-icon" (click)="back()" type="button"><em class="icon icon-chevron-left"></em> {{ 'Property & Electric Bill' | translate }}</button>
                <button class="btn btn-primary btn-with-icon" type="submit">{{ 'View Quote' | translate }} <em class="icon icon-chevron-right-full"></em></button>
            </div>
        </app-loading>
    </div>
</form>
