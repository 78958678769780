import { NgIf } from '@angular/common';
import { Component, Input, output, OutputEmitterRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, TranslateModule],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
})
export class ModalComponent {
    @Input() isOpen: boolean = false;

    closeEvent: OutputEmitterRef<void> = output();

    close(): void {
        this.closeEvent.emit();
    }
}
