import {
    ChangeDetectionStrategy,
    Component,
    Input,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-toggle',
    standalone: true,
    imports: [],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
    @Input() active: boolean = false;

    toggleEvent: OutputEmitterRef<boolean> = output<boolean>();

    constructor(private readonly activatedRoute: ActivatedRoute) {}

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    toggleActive(): void {
        if (this.isReadonly) {
            return;
        }

        this.active = !this.active;

        this.toggleEvent.emit(this.active);
    }
}
