import { CurrencyPipe, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Option } from 'ng-select2-component/lib/select2-interfaces';

import {
    ApiResultSolarPanelConfiguration,
    CompanyResponse,
    GetCalculationResponse,
} from '../../../domain/api-result';
import { ModalService } from '../../../page/results/services/modal.service';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { BoxComponent } from '../box/box.component';
import { PriceComponent } from '../price/price.component';
import { TabListComponent } from '../tab-list/tab-list.component';

@Component({
    selector: 'app-results-aside',
    standalone: true,
    imports: [
        AsideFooterComponent,
        AsideCompanyDataComponent,
        BoxComponent,
        TabListComponent,
        TranslateModule,
        CurrencyPipe,
        NgIf,
        LoadingComponent,
        PriceComponent,
    ],
    templateUrl: './results-aside.component.html',
    styleUrl: './results-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsAsideComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() apiResultSolarPanelConfiguration:
        | ApiResultSolarPanelConfiguration
        | undefined;
    @Input() selectedSolarPanel: Select2Option | undefined;
    @Input() selectedInverter: Select2Option | undefined;

    company: CompanyResponse | undefined;
    calculationId: string | null = null;

    constructor(
        private readonly modalService: ModalService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.company = this.activatedRoute.snapshot.data['company'];

        if (undefined === this.company) {
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        const calculationResponse: GetCalculationResponse | undefined =
            this.activatedRoute.snapshot.data['calculation'];

        if (undefined !== calculationResponse) {
            this.calculationId = calculationResponse.id;
        }

        this.changeDetectorRef.detectChanges();
    }
}
