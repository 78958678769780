import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, effect, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { ErrorService } from './services/error.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, LoadingComponent, NgIf, TranslateModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    isLoading: boolean = true;
    isError: boolean = false;
    errorMessage: string | null = null;

    constructor(
        private readonly errorService: ErrorService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        effect((): void => {
            this.errorMessage = this.errorService.errorSignal();

            if (null === this.errorMessage) {
                return;
            }

            this.isError = true;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit(): void {
        const preconnect: HTMLLinkElement = document.createElement('link');
        preconnect.rel = 'preconnect';
        preconnect.href = environment.API_URL;
        document.head.appendChild(preconnect);
    }

    activate(): void {
        this.isLoading = false;
    }
}
