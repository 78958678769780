<div class="price-container" [class.align-right]="alignRight" *ngIf="'cash' === activeTab">
    <app-loading [isLoading]="isLoading" [showContent]="prices.totalPrice !== 0">
        <span class="price">{{ prices.totalPrice | currency:'':'' }} {{ company?.currency_code ?? 'USD' }}</span>
    </app-loading>
    <app-loading [isLoading]="isLoading" [showContent]="prices.totalPricePerWp !== 0">
        <span class="price-per">{{ prices.totalPricePerWp | currency:'':'' }} {{ company?.currency_code ?? 'USD' }}/Wp</span>
    </app-loading>
    <span class="annotation">*{{ 'The price includes the subsidy' | translate }}</span>
</div>

<div class="price-container" [class.align-right]="alignRight" *ngIf="'leasing' === activeTab">
    <app-loading [isLoading]="isLoading" [showContent]="prices.totalPrice !== 0">
        <span class="price">{{ leasingPrice | currency:'':'' }} {{ company?.currency_code ?? 'USD' }}/{{ 'month' | translate }}</span>
    </app-loading>
    <app-loading [isLoading]="isLoading" [showContent]="prices.totalPricePerWp !== 0">
        <span class="price-per">{{ company?.financial_duration_months ?? 0 }} {{ 'month' | translate }} | RRSO {{ company?.financial_rrso ?? 0 }} %</span>
    </app-loading>
    <span class="annotation">*{{ 'The price includes the subsidy' | translate }}</span>
</div>

<div class="btn-container" *ngIf="!isReadonly">
    <button class="btn btn-primary" (click)="openModal()">{{ 'Order the full offer' | translate }}</button>
</div>

<p class="disclaimer">
    {{ 'THE OFFER PRESENTED IS PRELIMINARY AND MAY BE CHANGED AFTER DETAILED ANALYSIS BY AN ADVISOR.' | translate }}
    {{ 'ORDER THE FULL OFFER FOR THIS PURPOSE.' | translate }}
</p>
