import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    output,
    OutputEmitterRef,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-slider',
    standalone: true,
    imports: [],
    templateUrl: './slider.component.html',
    styleUrl: './slider.component.scss',
})
export class SliderComponent implements OnInit {
    @Input() value: number = 0;
    @Input() recommended: string = '';

    @ViewChild('toast', { static: true })
    toastElement!: ElementRef<HTMLElement>;

    @ViewChild('input', { static: true })
    inputElement!: ElementRef<HTMLInputElement>;

    show: boolean = false;

    updateValue: OutputEmitterRef<number> = output<number>();

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.inputElement.nativeElement.addEventListener(
            'mouseenter',
            (): void => {
                this.show = true;
                this.changeDetectorRef.detectChanges();
            },
        );
        this.inputElement.nativeElement.addEventListener(
            'mouseleave',
            (): void => {
                this.show = false;
                this.changeDetectorRef.detectChanges();
            },
        );
    }

    get toastLeft(): string {
        return `calc(${this.value}% * 0.957)`;
    }

    onInput(event: Event): void {
        this.updateValue.emit(Number((event.target as HTMLInputElement).value));
    }
}
