import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AnalyzeResponse, CompanyResponse } from '../../../domain/api-result';
import { FormValues } from '../../../page/form/form.component';
import {
    ModalData,
    ModalService,
} from '../../../page/results/services/modal.service';
import { ErrorService } from '../../../services/error.service';
import { SunnaApiService } from '../../../services/sunna-api.service';
import { ModalComponent } from '../modal/modal.component';
import { PhoneFormComponent } from '../phone-form/phone-form.component';

/**
 * Displays missing address modal with form.
 * To display this modal, add this component to
 * your view and call `ModalService.set{ModalType}(true)`
 */
@Component({
    selector: 'app-address-error-modal',
    standalone: true,
    imports: [ModalComponent, PhoneFormComponent, NgIf, TranslateModule],
    templateUrl: './address-error-modal.component.html',
    styleUrl: './address-error-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressErrorModalComponent implements OnInit {
    @Input() formValues: FormValues | undefined;
    @Input() name: string | undefined;

    isOpen: boolean = false;
    submitted: boolean = false;

    sunnaSlug: string = '';

    private errorMessage: string =
        'Unexpected error occurred. Contact administrator.';

    constructor(
        private readonly modalService: ModalService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly sunnaApiService: SunnaApiService,
        private readonly errorService: ErrorService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        this.translateService
            .get('Unexpected error occurred. Contact administrator.')
            .subscribe((value: string): void => {
                this.errorMessage = value;
            });

        this.modalService.addressErrorModal.subscribe(
            (value: ModalData): void => {
                this.isOpen = value.open;

                this.changeDetector.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        const company: CompanyResponse | undefined =
            this.activatedRoute.snapshot.data['company'];

        if (undefined === company) {
            this.errorService.addError(this.errorMessage);
            return;
        }

        this.sunnaSlug = company?.sunna_slug;
    }

    close(): void {
        this.modalService.setAddressErrorModalState(false);
        this.router.navigate([this.sunnaSlug]).then((): void => {});
    }

    formSubmit(phone: string): void {
        if (undefined === this.formValues) {
            this.errorService.addError(this.errorMessage);
            return;
        }

        const calculationId: AnalyzeResponse | undefined | string =
            this.activatedRoute.snapshot.data['analyze'];

        if (typeof calculationId !== 'string') {
            this.errorService.addError(this.errorMessage);
            return;
        }

        this.sunnaApiService
            .saveCalculation(calculationId, {
                phone_number: phone,
                sunna_slug: this.sunnaSlug,
                system_panel_module: null,
                system_inverter_module: null,
                has_battery: false,
                system_battery_module: null,
                system_battery_qty: null,
                financial_type: 'cash',
                consumption_energy_car_charger: 0,
                consumption_energy_storage: 0,
            })
            .subscribe({
                next: (): void => {
                    this.submitted = true;
                    this.changeDetector.detectChanges();
                },
                error: (): void => {
                    this.errorService.addError(this.errorMessage);
                },
            });
    }
}
