<div class="inputs">
    <div class="form-group">
        <label for="solar-panel">{{ 'Solar Panel' | translate }}</label>
        <ng-container *ngIf="isReadonly">{{ solarPanelLabel }}</ng-container>
        <select2
            *ngIf="!isReadonly"
            id="solar-panel"
            required
            [value]="solarPanelValue"
            [data]="solarPanelOptions"
            (update)="solarPanelUpdate($event)"
        ></select2>
    </div>
    <div class="form-group">
        <label for="inverter">{{ 'Inverter' | translate }}</label>
        <ng-container *ngIf="isReadonly">{{ inverterLabel }}</ng-container>
        <select2
            *ngIf="!isReadonly"
            id="inverter"
            required
            [value]="inverterValue"
            [data]="inverterOptions"
            (update)="inverterUpdate($event)"
        ></select2>
    </div>
</div>
