<div class="box">
    <div class="box-title">
        <ng-content select="[title]"></ng-content>
    </div>

    <div class="box-content" [class.without-footer]="!displayFooter">
        <ng-content select="[content]"></ng-content>
    </div>

    <div class="box-footer" *ngIf="displayFooter">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
