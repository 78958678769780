import { CurrencyPipe, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { InnerBoxComponent } from '../../../../components/results/inner-box/inner-box.component';
import { PriceComponent } from '../../../../components/results/price/price.component';
import { TabListComponent } from '../../../../components/results/tab-list/tab-list.component';
import { ToggleComponent } from '../../../../components/results/toggle/toggle.component';
import { LoadingComponent } from '../../../../components/shared/loading/loading.component';
import { CompanyResponse } from '../../../../domain/api-result';
import {
    CalculatePricesService,
    CalculationResultData,
} from '../../services/calculate-prices.service';

@Component({
    selector: 'app-offer',
    standalone: true,
    imports: [
        CurrencyPipe,
        InnerBoxComponent,
        NgIf,
        TranslateModule,
        TabListComponent,
        LoadingComponent,
        ToggleComponent,
        PriceComponent,
    ],
    templateUrl: './offer.component.html',
    styleUrl: './offer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() companyResponse: CompanyResponse | undefined;

    prices: CalculationResultData = {
        basePrice: 0,
        batteryPrice: 0,
        grantPrice: 0,
        inverterPrice: 0,
        panelPrice: 0,
        systemPower: 0,
        systemPrice: 0,
        totalBatteryPrice: 0,
        totalPrice: 0,
        totalPricePerWp: 0,
    };

    openModalEvent: OutputEmitterRef<void> = output();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly calculatePricesService: CalculatePricesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.calculatePricesService
            .calculationSub()
            .subscribe((data: CalculationResultData): void => {
                this.prices = data;
                this.changeDetectorRef.detectChanges();
            });

        this.calculatePricesService.forceRecalculate(undefined);
    }

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    openModal(): void {
        this.openModalEvent.emit();
    }
}
