<app-modal [isOpen]="isOpen" (closeEvent)="close()">
    <div *ngIf="!submitted">
        <p class="dialog-title">
            <ng-template *ngIf="name">{{ name }}, </ng-template>{{ 'we have a problem with the automatic analysis of your roof...' | translate }}
        </p>
        <p>
            <span [innerHTML]="'It\'s possible it\'s in an area we don\'t yet service or the data isn\'t sufficient.' | translate"></span>
            <br><br>
            <span [innerHTML]="'But it\'s not a problem! Give us your phone number and our advisor will prepare an analysis and offer for you free of charge.' | translate"></span>
        </p>

        <app-phone-form
            [submitText]="'I am ordering a free analysis' | translate"
            (formSubmitEvent)="formSubmit($event)"
        ></app-phone-form>
    </div>

    <div *ngIf="submitted">
        <p class="dialog-title">{{ 'We have accepted your request!' | translate }}</p>
        <p>{{ 'Wait for contact from our advisor.' | translate }}</p>
    </div>
</app-modal>
