import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Select2Module } from 'ng-select2-component';

import {
    ModalData,
    ModalService,
} from '../../../page/results/services/modal.service';
import { ModalComponent } from '../../shared/modal/modal.component';

@Component({
    selector: 'app-calculation-share-modal',
    standalone: true,
    imports: [
        ModalComponent,
        NgIf,
        ReactiveFormsModule,
        Select2Module,
        TranslateModule,
    ],
    templateUrl: './calculation-share-modal.component.html',
    styleUrl: './calculation-share-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationShareModalComponent {
    isOpen: boolean = false;
    url: string | undefined;

    constructor(
        private readonly modalService: ModalService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly router: Router,
    ) {
        this.modalService.calculationShareModal.subscribe(
            (value: ModalData): void => {
                if (!value.open) {
                    this.isOpen = false;
                    return;
                }

                const data: {
                    sunnaSlug: string | undefined;
                    calculationId: string | undefined;
                } = value.data as {
                    sunnaSlug: string | undefined;
                    calculationId: string | undefined;
                };

                if (
                    undefined === data.sunnaSlug ||
                    undefined === data.calculationId
                ) {
                    console.error('Missing sunnaSlug or calculationId');
                    return;
                }

                this.isOpen = value.open;

                this.url =
                    window.location.origin +
                    this.router
                        .createUrlTree([
                            data.sunnaSlug,
                            'calculation',
                            data.calculationId,
                        ])
                        .toString();

                this.changeDetector.detectChanges();
            },
        );
    }

    close(): void {
        this.url = undefined;
        this.modalService.setCalculationShareModalState(false);
    }
}
