import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    private errorSignal$: WritableSignal<string | null> = signal(null);

    addError(message: string): void {
        this.errorSignal$.set(message);
    }

    get errorSignal(): WritableSignal<string | null> {
        return this.errorSignal$;
    }
}
