import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeParseReturnType } from 'zod';

import { ReturnAsideComponent } from '../../components/return/return-aside/return-aside.component';
import { CompanyResponse } from '../../domain/api-result';
import {
    LocalStorageCalculation,
    LocalStorageCalculationSchema,
} from '../../domain/local-storage';

@Component({
    selector: 'app-return',
    standalone: true,
    imports: [ReturnAsideComponent],
    templateUrl: './return.component.html',
    styleUrl: './return.component.scss',
})
export class ReturnComponent implements OnInit {
    company: CompanyResponse | undefined;
    localStorageCalculation: LocalStorageCalculation | undefined;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        const jsonPreviousCalculation: string | null = localStorage.getItem(
            'previousCalculation',
        );

        if (null === jsonPreviousCalculation) {
            this.onStartNewCalculation();
            return;
        }

        this.localStorageCalculation = JSON.parse(jsonPreviousCalculation);

        const parse: SafeParseReturnType<unknown, unknown> =
            LocalStorageCalculationSchema.safeParse(
                this.localStorageCalculation,
            );

        if (!parse.success) {
            this.onStartNewCalculation();
        }
    }

    ngOnInit(): void {
        this.company = this.activatedRoute.snapshot.data['company'] as
            | CompanyResponse
            | undefined;

        if (undefined === this.company) {
            this.router.navigate(['']).then((): void => {});
        }
    }

    public onReturnToCalculation(): void {
        if (undefined === this.company) {
            this.router.navigate(['']).then((): void => {});
            return;
        }

        this.router
            .navigate([
                this.company.sunna_slug,
                'calculation',
                this.localStorageCalculation?.calculationId,
            ])
            .then((): void => {});
    }

    public onStartNewCalculation(): void {
        if (undefined === this.company) {
            this.router.navigate(['']).then((): void => {});
            return;
        }

        localStorage.removeItem('previousCalculation');

        this.router.navigate([this.company.sunna_slug]).then((): void => {});
    }
}
