import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    output,
    OutputEmitterRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import {
    ModalData,
    ModalService,
} from '../../../page/results/services/modal.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { PhoneFormComponent } from '../../shared/phone-form/phone-form.component';

@Component({
    selector: 'app-phone-modal',
    standalone: true,
    imports: [ModalComponent, PhoneFormComponent, TranslateModule],
    templateUrl: './phone-modal.component.html',
    styleUrl: './phone-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneModalComponent {
    submitEvent: OutputEmitterRef<string> = output<string>();

    isOpen: boolean = false;

    constructor(
        private readonly modalService: ModalService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {
        this.modalService.phoneModal.subscribe((value: ModalData): void => {
            this.isOpen = value.open;

            this.changeDetector.detectChanges();
        });
    }

    close(): void {
        this.modalService.setPhoneModalState(false);
    }

    formSubmit(phone: string): void {
        this.close();

        this.submitEvent.emit(phone);
    }
}
