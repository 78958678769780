<h4>{{ 'The AI algorithm will analyze your roof and energy consumption and prepare a project and offer for you.' | translate }}</h4>
<p>{{ 'Provide address and energy consumption.' | translate }}</p>

<form [formGroup]="step1Form" (ngSubmit)="formSubmit()">
    <div class="form-group" [class.have-errors]="address?.invalid && address?.touched">
        <label for="address">{{ 'Address' | translate }}</label>
        <div class="input-group">
            <em class="icon icon-address"></em>
            <input autofocus type="text" #autocompleteInput formControlName="address" [placeholder]="'Property Address' | translate" class="field-icon field-icon-address" id="address">
        </div>
        <div class="form-errors" *ngIf="address?.invalid && address?.touched">
            <div *ngIf="address?.errors?.['required']">{{ 'Address is required.' | translate }}</div>
        </div>
    </div>
    <div class="form-group" [class.have-errors]="bill?.invalid && bill?.touched">
        <label for="bill">{{ 'Electric Bill' | translate }}</label>
        <div class="input-group">
            <em class="icon icon-bill"></em>
            <input type="number" formControlName="bill" placeholder="100" min="0" step="0.01" id="bill">
        </div>
        <div class="form-errors" *ngIf="bill?.invalid && bill?.touched">
            <div *ngIf="bill?.errors?.['required']">{{ 'Electric Bill is required.' | translate }}</div>
        </div>
    </div>
    <div class="form-group" [class.have-errors]="equipment?.invalid && equipment?.touched">
        <label for="equipment">{{ 'Equipment' | translate }}</label>
        <select2
            id="equipment"
            required
            formControlName="equipment"
            [value]="1"
            [data]="equipmentOptions"
        ></select2>
        <div class="form-errors" *ngIf="equipment?.invalid && equipment?.touched">
            <div *ngIf="equipment?.errors?.['required']">{{ 'Equipment is required.' | translate }}</div>
        </div>
    </div>
    <div class="step">{{ 'Step' | translate }} 1/2</div>
    <div class="buttons">
        <button class="btn btn-primary btn-with-icon" type="submit">{{ 'Continue' | translate }} <em class="icon icon-chevron-right-full"></em></button>
    </div>
</form>
