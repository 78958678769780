<div class="aside-content">
    <app-aside-company-data></app-aside-company-data>

    <app-box>
        <ng-container title>
            {{ 'Your summary' | translate }}
        </ng-container>

        <ng-container content>
            <app-loading [isLoading]="isLoading" [showContent]="true">
                <ul class="check-list">
                    <li>{{ apiResultSolarPanelConfiguration?.systemPowerDcKwh ?? 0 }} kWp</li>
                    <li>{{ 'Construction' | translate }}</li>
                    <li>{{ 'The price includes tax' | translate }}</li>
                    <li>{{ apiResultSolarPanelConfiguration?.totalPanelCount ?? 0 }} {{ 'panels' | translate }} {{ selectedSolarPanel?.label }}</li>
                    <li>{{ 'Full installation' | translate }}</li>
                    <li>{{ 'Inverter' | translate }} {{ selectedInverter?.label }}</li>
                    <li>{{ 'Warranty' | translate }}</li>
                </ul>
            </app-loading>
        </ng-container>

        <ng-container footer>
            <div class="footer-content">
                <app-tab-list *ngIf="company?.financial_enabled ?? false"></app-tab-list>

                <app-price
                    [company]="company"
                    [isLoading]="isLoading"
                ></app-price>
            </div>
        </ng-container>
    </app-box>
</div>

<app-aside-footer></app-aside-footer>
