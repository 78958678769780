import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LoadingComponent } from '../../shared/loading/loading.component';

@Component({
    selector: 'app-box',
    standalone: true,
    imports: [NgIf, LoadingComponent],
    templateUrl: './box.component.html',
    styleUrl: './box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
    @Input() displayFooter: boolean = true;
}
