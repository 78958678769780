import {
    CurrencyPipe,
    DecimalPipe,
    NgIf,
    NgOptimizedImage,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Select2Option } from 'ng-select2-component/lib/select2-interfaces';
import { z } from 'zod';

import { environment } from '../../../environments/environment';
import { BoxComponent } from '../../components/results/box/box.component';
import { CalculationShareModalComponent } from '../../components/results/calculation-share-modal/calculation-share-modal.component';
import { CountInputComponent } from '../../components/results/count-input/count-input.component';
import { ImagePanelsComponent } from '../../components/results/image-panels/image-panels.component';
import { InnerBoxComponent } from '../../components/results/inner-box/inner-box.component';
import { PhoneModalComponent } from '../../components/results/phone-modal/phone-modal.component';
import { ProgressBarComponent } from '../../components/results/progress-bar/progress-bar.component';
import { ResultsAsideComponent } from '../../components/results/results-aside/results-aside.component';
import { TabListComponent } from '../../components/results/tab-list/tab-list.component';
import { AddressErrorModalComponent } from '../../components/shared/address-error-modal/address-error-modal.component';
import { LoadingComponent } from '../../components/shared/loading/loading.component';
import {
    ApiResultSolarPanelConfiguration,
    CalculationResponse,
    CompanyResponse,
    GetCalculationResponse,
    Module,
    ResultResponse,
} from '../../domain/api-result';
import {
    ActiveTabService,
    FinancingType,
} from '../../services/active-tab.service';
import { LanguageService } from '../../services/language.service';
import { SunnaApiService } from '../../services/sunna-api.service';
import { FormValues } from '../form/form.component';
import { ComponentsSelectComponent } from './page-components/components-select/components-select.component';
import { ConceptComponent } from './page-components/concept/concept.component';
import { EnergyStorageComponent } from './page-components/energy-storage/energy-storage.component';
import { OfferComponent } from './page-components/offer/offer.component';
import {
    CalculatePricesService,
    CalculationInfoData,
} from './services/calculate-prices.service';
import { ModalService } from './services/modal.service';

export interface QueryValues {
    jobId: string | undefined;
    firstSurpassIndex: string | undefined;
    calculationId: string | undefined;
    address: string | undefined;
    latitude: string | undefined;
    longitude: string | undefined;
    bill: string | undefined;
    equipment: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
}

const QueryValuesSchema: z.ZodSchema = z.object({
    jobId: z.string(),
    firstSurpassIndex: z.string(),
    address: z.string(),
    latitude: z.string(),
    longitude: z.string(),
    bill: z.string(),
    equipment: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
});

@Component({
    selector: 'app-results',
    standalone: true,
    imports: [
        ResultsAsideComponent,
        BoxComponent,
        NgOptimizedImage,
        CountInputComponent,
        ProgressBarComponent,
        ComponentsSelectComponent,
        TabListComponent,
        InnerBoxComponent,
        NgIf,
        ImagePanelsComponent,
        PhoneModalComponent,
        TranslateModule,
        ConceptComponent,
        DecimalPipe,
        CurrencyPipe,
        CalculationShareModalComponent,
        OfferComponent,
        CalculationShareModalComponent,
        LoadingComponent,
        AddressErrorModalComponent,
        EnergyStorageComponent,
    ],
    templateUrl: './results.component.html',
    styleUrl: './results.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsComponent implements OnInit {
    apiResultSolarPanelConfiguration:
        | ApiResultSolarPanelConfiguration
        | undefined;
    companyResponse: CompanyResponse | undefined;
    calculationResponse: GetCalculationResponse | undefined;
    formValues: FormValues | undefined;

    image: string | undefined;

    jobId: string = '';
    currentIndex: number = -1;

    havePrevPage: boolean = false;
    haveNextPage: boolean = false;

    sunnaSlug: string = '';

    isLoading: boolean = true;

    solarPanelOptions: { [key: string]: Select2Option } = {};
    inverterOptions: { [key: string]: Select2Option } = {};
    batteryTypeOptions: { [key: string]: Select2Option } = {};

    selectedSolarPanel: Select2Option | undefined;
    selectedInverter: Select2Option | undefined;
    selectedBatteryType: Select2Option | undefined;
    batteryCount: number = 1;
    batteryEnabled: boolean = false;

    calculationId: string | null = null;

    modules: Module[] | undefined;

    financingType: FinancingType = 'cash';

    energyStorageSliderValue: number = 0;
    chargingCarSliderValue: number = 0;

    constructor(
        private readonly sunnaApiService: SunnaApiService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly modalService: ModalService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly calculatePricesService: CalculatePricesService,
        private readonly languageService: LanguageService,
        private readonly activeTabService: ActiveTabService,
    ) {
        this.activeTabService.activeTab.subscribe(
            (activeTab: FinancingType): void => {
                this.financingType = activeTab;
            },
        );
    }

    ngOnInit(): void {
        const company: CompanyResponse | undefined =
            this.activatedRoute.snapshot.data['company'];

        if (undefined === company) {
            this.modalService.setAddressErrorModalState(true);
            this.isLoading = false;
            return;
        }

        this.languageService.setLanguageOptions(company);

        this.sunnaSlug = company.sunna_slug;

        this.calculationResponse =
            this.activatedRoute.snapshot.data['calculation'];

        try {
            if (undefined !== this.calculationResponse) {
                this.onCalculation(this.calculationResponse);
            } else {
                this.onResult();
            }
        } catch {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        if ('' === this.jobId || 0 > this.currentIndex) {
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
            this.modalService.setAddressErrorModalState(true);
            return;
        }

        this.companyResponse = company;

        this.fetchModules().then((): void => {});
        this.getResult().then((): void => {});

        this.reloadCalculation();
    }

    private onCalculation(calculationResponse: GetCalculationResponse): void {
        this.formValues = {
            address: '',
            bill: 0,
            email: '',
            equipment: 1,
            firstName: '',
            lastName: '',
            latitude: 0,
            longitude: 0,
        };

        this.jobId = calculationResponse.job_id as string;
        this.currentIndex = calculationResponse.configuration_index as number;

        this.calculationId = calculationResponse.id;

        if (null !== calculationResponse.system_panel_module) {
            this.selectedSolarPanel =
                this.solarPanelOptions[calculationResponse.system_panel_module];
        }

        if (null !== calculationResponse.system_inverter_module) {
            this.selectedInverter =
                this.inverterOptions[
                    calculationResponse.system_inverter_module
                ];
        }

        if (null !== calculationResponse.system_battery_module) {
            this.selectedBatteryType =
                this.batteryTypeOptions[
                    calculationResponse.system_battery_module
                ];
        }

        this.batteryEnabled = calculationResponse.has_battery;

        if (null !== calculationResponse.system_battery_qty) {
            this.batteryCount = calculationResponse.system_battery_qty;
        } else {
            this.batteryCount = 1;
        }
    }

    /**
     * @throws Error when queryParams does not have a correct schema
     */
    private onResult(): void {
        const queryParams: QueryValues = this.activatedRoute.snapshot
            .queryParams as QueryValues;

        const res: z.SafeParseReturnType<unknown, unknown> =
            QueryValuesSchema.safeParse(queryParams);

        if (!res.success) {
            throw new Error('Incorrect schema');
        }

        this.formValues = {
            address: queryParams.address as string,
            bill: Number(queryParams.bill as string),
            email: queryParams.email as string,
            equipment: Number(queryParams.equipment as string),
            firstName: queryParams.firstName as string,
            lastName: queryParams.lastName as string,
            latitude: Number(queryParams.latitude as string),
            longitude: Number(queryParams.longitude as string),
        };

        this.jobId = queryParams.jobId as string;
        this.currentIndex = Number(queryParams.firstSurpassIndex as string);

        if (
            undefined !== queryParams?.calculationId &&
            queryParams?.calculationId.length > 0
        ) {
            this.calculationId = queryParams?.calculationId;
        }
    }

    reloadCalculation(): void {
        const data: CalculationInfoData = {
            apiResultSolarPanelConfiguration:
                this.apiResultSolarPanelConfiguration,
            batteryCount: this.batteryCount,
            batteryEnabled: this.batteryEnabled,
            companyResponse: this.companyResponse,
            modules: this.modules,
            selectedBatteryTypeValue: this.selectedBatteryType?.value,
            selectedInverterValue: this.selectedInverter?.value,
            selectedSolarPanelValue: this.selectedSolarPanel?.value,
        };

        this.calculatePricesService.forceRecalculate(data);

        this.changeDetectorRef.detectChanges();
    }

    get oldBillMonthly(): number {
        return this.apiResultSolarPanelConfiguration?.oldBillMonthly ?? 0;
    }

    get newBillMonthly(): number {
        const newBill: number | undefined =
            this.apiResultSolarPanelConfiguration?.newBillMonthly;

        if (undefined === newBill || 0 > newBill) {
            return 0;
        }

        return newBill;
    }

    async fetchModules(): Promise<void> {
        this.modules = this.activatedRoute.snapshot.data['modules'];

        if (undefined === this.modules) {
            this.modalService.setAddressErrorModalState(true);
            this.isLoading = false;
            return;
        }

        this.modules.forEach((module: Module): void => {
            if ('panel' === module.category) {
                this.solarPanelOptions[module.id] = {
                    label: module.name,
                    value: module.id,
                };
            } else if ('inverter' === module.category) {
                this.inverterOptions[module.id] = {
                    label: module.name,
                    value: module.id,
                };
            } else if ('battery' === module.category) {
                this.batteryTypeOptions[module.id] = {
                    label: module.name,
                    value: module.id,
                };
            }
        });

        this.reloadCalculation();
        this.changeDetectorRef.detectChanges();
    }

    async getResult(): Promise<void> {
        this.isLoading = true;

        this.sunnaApiService.result(this.jobId, this.currentIndex).subscribe({
            next: (apiResult: ResultResponse): void => {
                let imageUri: string = apiResult.imageUrl;

                if ('/' === imageUri.charAt(0)) {
                    imageUri = imageUri.substring(1);
                }

                this.havePrevPage = null !== apiResult.pagination.previous;
                this.haveNextPage = null !== apiResult.pagination.next;

                this.image = `${environment.API_URL}${imageUri}`;

                this.apiResultSolarPanelConfiguration =
                    apiResult.solarPanelConfiguration;

                this.isLoading = false;
                this.reloadCalculation();
                this.changeDetectorRef.detectChanges();
            },
            error: (): void => {
                this.isLoading = false;
                this.reloadCalculation();
                this.changeDetectorRef.detectChanges();

                this.modalService.setAddressErrorModalState(true);
            },
        });
    }

    changePanelsCount(count: number): void {
        if (undefined === this.apiResultSolarPanelConfiguration) {
            return;
        }

        const panelsCount: number =
            this.apiResultSolarPanelConfiguration.totalPanelCount;

        if (count > panelsCount) {
            this.currentIndex++;
        } else {
            this.currentIndex--;
        }

        if (1 >= this.currentIndex) {
            this.currentIndex = 1;
            return;
        }

        this.image = undefined;
        this.isLoading = true;

        this.getResult().then((): void => {});
    }

    openModal(): void {
        this.modalService.setPhoneModalState(true);
    }

    getStringValue(item: Select2Option | undefined): string {
        if (
            undefined === item ||
            'object' === typeof item.value ||
            'boolean' === typeof item.value
        ) {
            return '';
        }

        return item.value.toString();
    }

    submitModal(phoneNumber: string): void {
        if (undefined === this.formValues || null === this.calculationId) {
            this.modalService.setAddressErrorModalState(true);
            this.isLoading = false;
            return;
        }

        this.sunnaApiService
            .saveCalculation(this.calculationId, {
                panel_number:
                    this.apiResultSolarPanelConfiguration?.totalPanelCount ?? 0,
                configuration_index: this.currentIndex,
                job_id: this.jobId,
                phone_number: phoneNumber,
                sunna_slug: this.sunnaSlug,
                system_panel_module: this.getStringValue(
                    this.selectedSolarPanel,
                ),
                system_inverter_module: this.getStringValue(
                    this.selectedInverter,
                ),
                has_battery: this.batteryEnabled,
                system_battery_module: this.getStringValue(
                    this.selectedBatteryType,
                ),
                system_battery_qty: this.batteryCount,
                financial_type: this.financingType,
                consumption_energy_storage: this.energyStorageSliderValue,
                consumption_energy_car_charger: this.chargingCarSliderValue,
            })
            .subscribe({
                next: (result: CalculationResponse): void => {
                    Sentry.metrics.increment('sunna_success', 1, {
                        tags: { company: this.sunnaSlug },
                    });

                    this.router
                        .navigate([
                            this.sunnaSlug,
                            'calculation',
                            result.calculation_id,
                        ])
                        .then((): void => {});
                    localStorage.setItem(
                        'previousCalculation',
                        JSON.stringify({
                            company: this.sunnaSlug,
                            power:
                                this.apiResultSolarPanelConfiguration
                                    ?.systemPowerDcKwh ?? 0,
                            address:
                                this.apiResultSolarPanelConfiguration
                                    ?.address ?? '',
                            calculationId: result.calculation_id,
                        }),
                    );
                },
                error: (): void => {
                    this.modalService.setAddressErrorModalState(true);
                    this.isLoading = false;
                },
            });
    }

    toggleBatteryUpdate(value: boolean): void {
        this.batteryEnabled = value;

        this.reloadCalculation();
    }

    solarPanelUpdate(value: string): void {
        this.selectedSolarPanel = this.solarPanelOptions[value];

        this.reloadCalculation();
    }

    inverterUpdate(value: string): void {
        this.selectedInverter = this.inverterOptions[value];

        this.reloadCalculation();
    }

    batteryTypeUpdate(value: string): void {
        this.selectedBatteryType = this.batteryTypeOptions[value];

        this.reloadCalculation();
    }

    batteryCountUpdate(value: number): void {
        this.batteryCount = value;

        this.reloadCalculation();
    }

    sliderValuesUpdate(value: {
        energyStorage: number;
        chargingCar: number;
    }): void {
        this.energyStorageSliderValue = value.energyStorage;
        this.chargingCarSliderValue = value.chargingCar;
    }

    share(): void {
        if (null === this.calculationId) {
            return;
        }

        this.modalService.setCalculationShareModalState(true, {
            sunnaSlug: this.sunnaSlug,
            calculationId: this.calculationId,
        });
    }
}
