<div class="concept-info">
    <div class="concept-box concept-panels">
        <em class="icon icon-photovoltaic"></em>
        <div class="concept-headline">
            <p>{{ 'Number of modules' | translate }}</p>
        </div>
        <div class="concept-content">
            <app-loading [isLoading]="isLoading" [showContent]="true">
                <app-count-input
                    [allowIncrement]="allowIncrement"
                    [allowDecrement]="allowDecrement"
                    [stopChange]="true"
                    [value]="panelsCount"
                    (changeCount)="changePanelsCount($event)"
                ></app-count-input>
            </app-loading>
        </div>
    </div>

    <div class="concept-box">
        <em class="icon icon-energy"></em>
        <div class="concept-headline">
            <p>{{ 'System power' | translate }}</p>
        </div>
        <div class="concept-content">
            <app-loading [isLoading]="isLoading" [showContent]="systemPower !== 0" [inline]="true">
                <span class="big-number">{{ systemPower | number: '1.2-2' }}</span>&nbsp;
            </app-loading>
            <span class="concept-sub-headline">kWp</span>
        </div>
    </div>
</div>

<app-loading [isLoading]="isLoading" [showContent]="(apiResultSolarPanelConfiguration?.panelCoordinatePoints ?? []).length > 0">
    <app-image-panels
        [image]="image"
        [panelCoordinatePoints]="apiResultSolarPanelConfiguration?.panelCoordinatePoints ?? []"
    ></app-image-panels>
</app-loading>

<div class="concept-info">
    <div class="concept-box concept-energy">
        <em class="icon icon-house"></em>
        <div class="concept-headline">
            <p>{{ 'Covering energy needs' | translate }}</p>
            <app-loading [isLoading]="isLoading" [showContent]="consumption !== '0.00'">
                <p class="sub"><em class="icon icon-info"></em>{{ 'Consumption consumption kWh/year' | translate:{consumption: consumption} }}</p>
            </app-loading>
        </div>
        <app-loading [isLoading]="isLoading" [showContent]="true">
            <app-progress-bar [start]="50" [value]="energyOffsetPercent" [end]="200" unit="%"></app-progress-bar>
        </app-loading>
    </div>
</div>
<div class="concept-info">
    <div class="concept-box style-2">
        <div class="concept-headline">
            <p>{{ 'Estimated production' | translate }}</p>
        </div>
        <div class="concept-content">
            <em class="icon icon-energy"></em>
            <div class="concept-headline">
                <app-loading [isLoading]="isLoading" [showContent]="yearlyEnergyDcKwh !== 0" [inline]="true">
                    <span class="big-number">{{ yearlyEnergyDcKwh | number: '1.2-2' }}</span>&nbsp;
                </app-loading>
                <span class="concept-sub-headline">kWh / {{ 'year' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="concept-box style-2">
        <div class="concept-headline">
            <p>{{ 'New energy bill' | translate }}</p>
        </div>
        <div class="concept-content">
            <em class="icon icon-currency"></em>
            <div class="price">
                <div class="concept-value concept-crossed">
                    <app-loading [isLoading]="isLoading" [showContent]="oldBillMonthly !== 0">
                        <span>{{ oldBillMonthly | currency:'':'' }}</span>
                    </app-loading>
                    <span class="concept-sub-headline">{{ currency }} / {{ 'month' | translate }}</span>
                </div>
                <div class="concept-value">
                    <app-loading [isLoading]="isLoading" [showContent]="newBillMonthly !== 0" [inline]="true">
                        <span class="big-number">{{ newBillMonthly | currency:'':'' }}</span>&nbsp;
                    </app-loading>
                    <span class="concept-sub-headline">{{ currency }} / {{ 'month' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
