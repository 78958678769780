<div class="headline">
    <div class="title">
        <ng-content select="[title]"></ng-content>
    </div>
    <div class="sub-title">
        <ng-content select="[sub-title]"></ng-content>
    </div>
</div>

<div class="content">
    <ng-content select="[content]"></ng-content>
</div>
