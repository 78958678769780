import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CompanyResponse } from '../../../domain/api-result';
import { AsideCompanyDataComponent } from '../../shared/aside-company-data/aside-company-data.component';
import { AsideFooterComponent } from '../../shared/aside-footer/aside-footer.component';

@Component({
    selector: 'app-form-aside',
    standalone: true,
    imports: [AsideCompanyDataComponent, AsideFooterComponent, TranslateModule],
    templateUrl: './form-aside.component.html',
    styleUrl: './form-aside.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAsideComponent {
    constructor(private readonly activatedRoute: ActivatedRoute) {}

    get companyName(): string {
        const companyData: CompanyResponse | undefined =
            this.activatedRoute.snapshot.data['company'];

        if (undefined === companyData) {
            return '';
        }

        return companyData.name;
    }
}
