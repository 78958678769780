<div class="offer-content">
    <app-tab-list *ngIf="companyResponse?.financial_enabled ?? false"></app-tab-list>

    <app-loading [isLoading]="isLoading" [showContent]="true">
        <app-inner-box>
            <ng-container title>{{ 'Your system' | translate }}</ng-container>
            <ng-container sub-title>{{ prices.systemPrice | currency:'':'' }} <span class="smaller">{{ companyResponse?.currency_code ?? 'USD' }}</span></ng-container>
            <ng-container content *ngIf="'' !== (companyResponse?.proposal_description ?? '').trim()">
                <div [innerHTML]="companyResponse?.proposal_description"></div>
            </ng-container>
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading" [showContent]="true">
        <app-inner-box>
            <ng-container title>{{ 'Warranty' | translate }}</ng-container>
            <ng-container sub-title>{{ 'Included in the price' | translate }}</ng-container>
            <ng-container content *ngIf="'' !== (companyResponse?.warranty_description ?? '').trim()">
                <div [innerHTML]="companyResponse?.warranty_description"></div>
            </ng-container>
        </app-inner-box>
    </app-loading>
    <app-loading [isLoading]="isLoading" [showContent]="true">
        <app-inner-box *ngIf="companyResponse?.dotations_enabled">
            <ng-container title>{{ 'Grant' | translate }}</ng-container>
            <ng-container sub-title>{{ prices.grantPrice | currency:'':'' }} <span class="smaller">{{ companyResponse?.currency_code ?? 'USD' }}</span></ng-container>
            <ng-container content *ngIf="'' !== (companyResponse?.dotations_description ?? '').trim()">
                <div [innerHTML]="companyResponse?.dotations_description"></div>
            </ng-container>
        </app-inner-box>
    </app-loading>
    <div class="offer-price">
        <app-price
            [alignRight]="true"
            [company]="companyResponse"
            [isLoading]="isLoading"
        ></app-price>
    </div>
</div>
