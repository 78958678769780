<div class="aside-company-data">
    <app-loading [isLoading]="isLoading" [showContent]="true">
        <img *ngIf="companyLogo" [ngSrc]="companyLogo" priority width="54" height="54" [alt]="companyName">
        <img *ngIf="!companyLogo" ngSrc="/assets/company.webp" priority width="54" height="54" [alt]="companyName">
    </app-loading>

    <app-loading [isLoading]="isLoading" [showContent]="true">
        <p>{{ companyName }}</p>
    </app-loading>
</div>
