import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    standalone: true,
    imports: [],
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
    @Input() value!: number | string;

    @Input() start: number = 50;
    @Input() end: number = 200;
    @Input() unit: string = '%';

    constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

    get roundedValue(): number | string {
        if ('number' === typeof this.value) {
            return Math.round(this.value);
        }

        return this.value;
    }

    get minValue(): number {
        if ('number' === typeof this.value && this.value < this.start) {
            this.start = Math.round(this.value) - 50;

            if (0 > this.start) {
                this.start = 0;
            }

            this.changeDetectorRef.detectChanges();
        }

        return this.start;
    }

    get maxValue(): number {
        if ('number' === typeof this.value && this.value > this.end) {
            this.end = Math.round(this.value) + 50;
            this.changeDetectorRef.detectChanges();
        }

        return this.end;
    }

    calcWidth(): string {
        if ('string' === typeof this.value) {
            return '0px';
        }

        const calc: number = Math.round(
            (100 / (this.end - this.start)) * (this.value - this.start),
        );

        return `${calc}%`;
    }
}
