import { CurrencyPipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CompanyResponse } from '../../../domain/api-result';
import {
    CalculatePricesService,
    CalculationResultData,
} from '../../../page/results/services/calculate-prices.service';
import { ModalService } from '../../../page/results/services/modal.service';
import {
    ActiveTabService,
    FinancingType,
} from '../../../services/active-tab.service';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { TabListComponent } from '../tab-list/tab-list.component';

@Component({
    selector: 'app-price',
    standalone: true,
    imports: [
        CurrencyPipe,
        LoadingComponent,
        NgIf,
        TabListComponent,
        TranslateModule,
    ],
    templateUrl: './price.component.html',
    styleUrl: './price.component.scss',
})
export class PriceComponent implements OnInit {
    @Input() alignRight: boolean = false;
    @Input() company: CompanyResponse | undefined;
    @Input() isLoading: boolean = false;

    prices: CalculationResultData = {
        basePrice: 0,
        batteryPrice: 0,
        grantPrice: 0,
        inverterPrice: 0,
        panelPrice: 0,
        systemPower: 0,
        systemPrice: 0,
        totalBatteryPrice: 0,
        totalPrice: 0,
        totalPricePerWp: 0,
    };

    activeTab: FinancingType = 'cash';

    constructor(
        private readonly modalService: ModalService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly calculatePricesService: CalculatePricesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly activeTabService: ActiveTabService,
    ) {
        this.activeTabService.activeTab.subscribe(
            (activeTab: FinancingType): void => {
                this.activeTab = activeTab;
                this.changeDetectorRef.detectChanges();
            },
        );
    }

    ngOnInit(): void {
        this.calculatePricesService
            .calculationSub()
            .subscribe((data: CalculationResultData): void => {
                this.prices = data;
                this.changeDetectorRef.detectChanges();
            });

        this.calculatePricesService.forceRecalculate(undefined);
    }

    get isReadonly(): boolean {
        return undefined !== this.activatedRoute.snapshot.data['calculation'];
    }

    get leasingPrice(): number {
        if (
            null === this.company?.financial_duration_months ||
            undefined === this.company?.financial_duration_months ||
            null === this.company?.financial_rrso ||
            undefined === this.company?.financial_rrso
        ) {
            return 0;
        }

        const rrsoPercent: number = this.company.financial_rrso / 100;

        let z: number = 0;

        for (
            let i: number = 1;
            i <= this.company.financial_duration_months;
            i++
        ) {
            z += 1 / Math.pow(1 + rrsoPercent / 12, i);
        }

        return this.prices.totalPrice / z;
    }

    openModal(): void {
        this.modalService.setPhoneModalState(true);
    }
}
